import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../components/layout";
import { Box } from "../components/rebrand/core/box";
import { Grid } from "../components/rebrand/core/Grid/Grid";
import { PageHeaderSection } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { Section } from "../components/rebrand/sections/Section/Section";
import { SEO } from "../components/seo";

export const pageQuery = graphql`
  query PrivacyPage {
    site {
      siteMetadata {
        pages {
          privacy {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

interface PrivacyPageProps extends PageProps {
    data: Queries.PrivacyPageQuery;
}

export default function PrivacyPage({ location: { pathname }, data: { site } }: PrivacyPageProps) {

    const { title, description, keywords } = site?.siteMetadata?.pages?.privacy as Queries.SiteSiteMetadataPagesPrivacy;

    const body = `POLARYCS SpA rige su Política de Privacidad con el fin de resguardar la seguridad, confidencialidad y privacidad del usuario y/o visitante de esta página Web, así como de la propiedad intelectual de los contenidos de ésta, en conformidad a lo señalado en la Ley Nº 19.628 Sobre Protección de la Vida Privada en Chile, que tiene como objetivo principal garantizar el derecho a la privacidad y proteger los datos personales de las personas y su tratamiento.`
    const seo = {
        title: title as string,
        description: description as string,
        keywords: keywords as string,
        pathname,
    };

    return (
        <Layout>
            <SEO {...seo} />
            <PageHeaderSection title={title as string} subtitle={description} />
            <Section paddingTopSpacing={[0, 0]} disableGutters={false}>
                <Grid>
                    <Box gridColumn="1 / span 12">
                        {body}
                    </Box>
                </Grid>
            </Section>
        </Layout>
    );
}
